var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "my-1"
  }, [_c('b-button-group', [_vm.allowCreate() ? _c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.add($event);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Add')) + " ")]) : _vm._e(), _c('xlsx-workbook', [_c('xlsx-sheet', {
    attrs: {
      "collection": _vm.items,
      "sheet-name": "Sheet1"
    }
  }), _c('xlsx-download', {
    attrs: {
      "filename": "Export Data Supplier"
    }
  }, [_vm.allowCreate() ? _c('b-button', {
    attrs: {
      "variant": "success"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Export')) + " ")]) : _vm._e()], 1)], 1), _vm.allowCreate() ? _c('b-button', {
    attrs: {
      "variant": "warning"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.addimport($event);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Import')) + " ")]) : _vm._e(), _c('modal-import', {
    on: {
      "getData": _vm.getData
    }
  })], 1)], 1)], 1), _c('b-card', [_c('modal-supplier', {
    attrs: {
      "item": _vm.currentItem
    },
    on: {
      "loading": _vm.toggleLoading,
      "submit": _vm.submit,
      "getData": _vm.getData
    }
  }), _c('b-row', [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "2",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Per page'),
      "label-size": "sm",
      "label-for": "perPageSelect"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.pageOptions
    },
    model: {
      value: _vm.perPage,
      callback: function ($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "2",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Sort'),
      "label-size": "sm",
      "label-for": "sortBySelect"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-select', {
    staticClass: "w-100",
    attrs: {
      "size": "sm",
      "disabled": !_vm.sortBy
    },
    on: {
      "change": function ($event) {
        return _vm.getData();
      }
    },
    model: {
      value: _vm.sortDesc,
      callback: function ($$v) {
        _vm.sortDesc = $$v;
      },
      expression: "sortDesc"
    }
  }, [_c('option', {
    domProps: {
      "value": false
    }
  }, [_vm._v("Asc")]), _c('option', {
    domProps: {
      "value": true
    }
  }, [_vm._v("Desc")])])], 1)], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "8",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Filter'),
      "label-for": "filterInput",
      "size": "sm"
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": "Tekan enter untuk cari",
      "size": "sm"
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        $event.preventDefault();
        return _vm.getData();
      }
    },
    model: {
      value: _vm.filter,
      callback: function ($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "variant": "outline-primary",
      "size": "sm"
    },
    on: {
      "click": function ($event) {
        return _vm.getData();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Search')) + " ")])], 1)], 1)], 1)], 1), _vm.items.length > 0 ? [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "small": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "items": _vm.items,
      "fields": _vm.fields
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function (row) {
        return [_c('strong', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(row.index + 1) + " ")])];
      }
    }, {
      key: "cell(nama_pic)",
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(_vm.truncateString(item.nama, 15))), _c('br'), _c('small', [_vm._v(_vm._s(_vm.truncateString(item.no_hp_pic, 15) ? _vm.truncateString(item.no_hp_pic, 15) : "-"))])];
      }
    }, {
      key: "cell(nama_instansi)",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(_vm.truncateString(item.instansi, 15))), _c('br'), _c('small', [_vm._v(_vm._s(_vm.truncateString(item.nohp, 15) ? _vm.truncateString(item.nohp, 15) : "-"))])];
      }
    }, {
      key: "cell(top)",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('span', {
          attrs: {
            "title": item.top
          }
        }, [_vm._v(_vm._s(_vm.truncateString(item.top, 30)))]), _c('br')];
      }
    }, {
      key: "cell(alamat)",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('span', {
          attrs: {
            "title": item.alamat
          }
        }, [_vm._v(_vm._s(_vm.truncateString(item.alamat, 30)))]), _c('br')];
      }
    }, {
      key: "cell(memo)",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('span', {
          attrs: {
            "title": item.memo
          }
        }, [_vm._v(_vm._s(_vm.truncateString(item.memo, 30)))]), _c('br')];
      }
    }, {
      key: "cell(actions)",
      fn: function (row) {
        return [_vm.allowUpdate() ? _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "title": _vm.$t('Edit'),
            "size": "sm",
            "variant": "info"
          },
          on: {
            "click": function ($event) {
              return _vm.edit(row.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon"
          }
        })], 1) : _vm._e(), _vm.allowDelete() ? _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "title": _vm.$t('Delete'),
            "size": "sm",
            "variant": "danger"
          },
          on: {
            "click": function ($event) {
              return _vm.remove(row.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "TrashIcon"
          }
        })], 1) : _vm._e()];
      }
    }], null, false, 3853215768)
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)] : [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "alert alert-danger p-2"
  }, [_c('strong', [_vm._v(_vm._s(_vm.$t('Data tidak ada')))])])])]], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }