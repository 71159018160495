var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "modal-header-close": "",
      "id": "modal-supplier",
      "cancel-variant": "secondary",
      "ok-title": "Add",
      "cancel-title": "Batal",
      "centered": "",
      "title": "Form Supplier",
      "size": "lg",
      "no-close-on-esc": "",
      "no-close-on-backdrop": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header-close",
      fn: function () {
        return [_c('b-button', {
          staticClass: "close",
          attrs: {
            "variant": "danger"
          },
          on: {
            "click": function ($event) {
              _vm.resetForm();
              _vm.closemodal();
            }
          }
        }, [_vm._v(" × ")])];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function () {
        return [_c('section', {
          staticClass: "d-flex justify-content-end align-rekenings-center"
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "disabled": !_vm.isValidForm,
            "size": "sm",
            "variant": "primary"
          },
          on: {
            "click": _vm.submit1
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('Save')) + " ")]), _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "danger"
          },
          on: {
            "click": function ($event) {
              return _vm.closemodal();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('Close')) + " ")])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "nama"
    }
  }, [_vm._v(_vm._s(_vm.$t('Nama PIC'))), _c('span', {
    staticClass: "text-danger"
  }, [_c('i', [_vm._v(" (*)")])])]), _c('b-form-input', {
    attrs: {
      "id": "nama",
      "type": "text",
      "placeholder": "Ex: Budi"
    },
    model: {
      value: _vm.form.nama,
      callback: function ($$v) {
        _vm.$set(_vm.form, "nama", $$v);
      },
      expression: "form.nama"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "nohp"
    }
  }, [_vm._v(_vm._s(_vm.$t('No. Handphone PIC'))), _c('span', {
    staticClass: "text-danger"
  }, [_c('i', [_vm._v(" (*)")])])]), _c('b-form-input', {
    attrs: {
      "id": "nohp",
      "type": "text",
      "placeholder": "Ex: 08888888888"
    },
    model: {
      value: _vm.form.no_hp_pic,
      callback: function ($$v) {
        _vm.$set(_vm.form, "no_hp_pic", $$v);
      },
      expression: "form.no_hp_pic"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "instansi"
    }
  }, [_vm._v(_vm._s(_vm.$t('Nama Instansi'))), _c('span', {
    staticClass: "text-danger"
  }, [_c('i', [_vm._v(" (*)")])])]), _c('b-form-input', {
    attrs: {
      "id": "instansi",
      "type": "text",
      "placeholder": "Ex: PT. Indo Jaya"
    },
    model: {
      value: _vm.form.instansi,
      callback: function ($$v) {
        _vm.$set(_vm.form, "instansi", $$v);
      },
      expression: "form.instansi"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "nohp"
    }
  }, [_vm._v(_vm._s(_vm.$t('No. Handphone')) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_c('i', [_vm._v("(*)")])])]), _c('b-form-input', {
    attrs: {
      "id": "nohp",
      "type": "text",
      "placeholder": "Ex: 08888888888"
    },
    model: {
      value: _vm.form.nohp,
      callback: function ($$v) {
        _vm.$set(_vm.form, "nohp", $$v);
      },
      expression: "form.nohp"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "email"
    }
  }, [_vm._v(_vm._s(_vm.$t('Email')) + " "), _c('span', {
    staticClass: "text-info"
  }, [_c('i', [_vm._v("(opsional)")])])]), _c('b-form-input', {
    attrs: {
      "id": "email",
      "type": "email",
      "placeholder": "Ex: supplier@gmail.com"
    },
    model: {
      value: _vm.form.email,
      callback: function ($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "top"
    }
  }, [_vm._v(_vm._s(_vm.$t('Terms Of Payment')) + " "), _c('span', {
    staticClass: "text-info"
  }, [_c('i', [_vm._v("(opsional)")])])]), _c('b-form-input', {
    attrs: {
      "id": "top",
      "type": "text",
      "placeholder": "COD/Cash dll"
    },
    model: {
      value: _vm.form.top,
      callback: function ($$v) {
        _vm.$set(_vm.form, "top", $$v);
      },
      expression: "form.top"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "alamat"
    }
  }, [_vm._v(_vm._s(_vm.$t('Alamat')) + " "), _c('span', {
    staticClass: "text-info"
  }, [_c('i', [_vm._v("(opsional)")])])]), _c('b-form-textarea', {
    model: {
      value: _vm.form.alamat,
      callback: function ($$v) {
        _vm.$set(_vm.form, "alamat", $$v);
      },
      expression: "form.alamat"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "memo"
    }
  }, [_vm._v(_vm._s(_vm.$t('Memo')) + " "), _c('span', {
    staticClass: "text-info"
  }, [_c('i', [_vm._v("(opsional)")])])]), _c('b-form-textarea', {
    model: {
      value: _vm.form.memo,
      callback: function ($$v) {
        _vm.$set(_vm.form, "memo", $$v);
      },
      expression: "form.memo"
    }
  })], 1)], 1)], 1), _c('hr'), _c('div', [_c('b-form', {
    ref: "form",
    staticClass: "repeater-form",
    style: {
      height: _vm.trHeight
    },
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.repeateAgain($event);
      }
    }
  }, _vm._l(this.form.rekenings, function (item, index) {
    return _c('b-row', {
      key: item.id,
      ref: "row",
      refInFor: true,
      attrs: {
        "id": item.id
      }
    }, [_c('b-col', {
      attrs: {
        "md": "2"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": _vm.$t('Bank Name'),
        "label-for": "nama"
      }
    }, [_c('b-form-input', {
      attrs: {
        "id": "nama",
        "type": "text",
        "placeholder": "BSI/BRI etc"
      },
      model: {
        value: item.nama,
        callback: function ($$v) {
          _vm.$set(item, "nama", $$v);
        },
        expression: "item.nama"
      }
    })], 1)], 1), _c('b-col', {
      attrs: {
        "md": "4"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": _vm.$t('Bank Account Number'),
        "label-for": "no_rekening"
      }
    }, [_c('b-form-input', {
      attrs: {
        "id": "no_rekening",
        "type": "text",
        "placeholder": "81798169286"
      },
      model: {
        value: item.no_rekening,
        callback: function ($$v) {
          _vm.$set(item, "no_rekening", $$v);
        },
        expression: "item.no_rekening"
      }
    })], 1)], 1), _c('b-col', {
      attrs: {
        "md": "4"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": _vm.$t('Description'),
        "label-for": "description"
      }
    }, [_c('b-form-input', {
      attrs: {
        "id": "description",
        "type": "text",
        "placeholder": "A.n Heykama"
      },
      model: {
        value: item.deskripsi,
        callback: function ($$v) {
          _vm.$set(item, "deskripsi", $$v);
        },
        expression: "item.deskripsi"
      }
    })], 1)], 1), item.id == null ? _c('b-col', {
      staticClass: "mb-50",
      attrs: {
        "lg": "2",
        "md": "3"
      }
    }, [_c('b-button', {
      staticClass: "mt-0 mt-md-2",
      attrs: {
        "size": "sm",
        "variant": "outline-danger"
      },
      on: {
        "click": function ($event) {
          return _vm.removeItem(index);
        }
      }
    }, [_c('feather-icon', {
      staticClass: "mr-25",
      attrs: {
        "icon": "XIcon"
      }
    }), _c('span', [_vm._v(_vm._s(_vm.$t('Delete')))])], 1)], 1) : _vm._e(), item.id ? _c('b-col', {
      staticClass: "mb-50",
      attrs: {
        "lg": "2",
        "md": "3"
      }
    }, [_c('b-button', {
      staticClass: "mt-0 mt-md-2",
      attrs: {
        "size": "sm",
        "variant": "danger"
      },
      on: {
        "click": function ($event) {
          return _vm.remove(item);
        }
      }
    }, [_c('feather-icon', {
      staticClass: "mr-25",
      attrs: {
        "icon": "XIcon"
      }
    }), _c('span', [_vm._v(_vm._s(_vm.$t('Delete')))])], 1)], 1) : _vm._e(), _c('b-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('hr')])], 1);
  }), 1)], 1), _c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.repeateAgain
    }
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "PlusIcon"
    }
  }), _c('span', [_vm._v(_vm._s(_vm.$t('Add New')))])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }